.pro-sidebar {
    box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
}

.sidebar-header {
    padding: 10px 21px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: 1px;
    overflow: hidden;
    /*text-overflow: ellipsis;*/
    white-space: nowrap;
    color: #6B0772;
    margin-top: 0.75rem;
}

.pro-sidebar-footer, .pro-sidebar-footer .btn {
    font-size: 0.6rem !important;
    padding: 10px 16px;
}

.pro-sidebar > .pro-sidebar-inner {
    background-color: #ffffff !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    color: #625f6e;
    padding: 10px 15px !important;
}

.pro-sidebar {
    color: #2f2f2f !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    background-color: #f5f5f5 !important;
    color: #2f2f2f !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: #181818 !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
}
.filter-btn {
    background-color: rgb(250,250,250);
}

.order-code {
    font-weight: bolder !important;
}
.note-info {
    color: #353535;
    font-size: 12px;
}
.item-count {
    font-weight: bolder !important;
}
.highlight-value {
    color: #57035d;
    font-weight: bolder !important;
}

table tbody {
    font-size: 0.9rem;
}
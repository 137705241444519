.website-item {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-top: 20px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
}

.website-item .name {
    background: linear-gradient(#ffffff00, #00000033);
    color: #fff;
    width: 100%;
    align-items: end;
    display: flex;
    justify-content: start;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 16px;
}

.website-item.add {
    color: #808080;
    width: 100%;
    align-items: end;
    display: flex;
    justify-content: start;
    /*font-weight: bold;*/
    font-size: 1.2rem;
    padding: 16px;
    border-style: dashed;
    border-radius: 8px;
}

.website-item.add div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.website-item .options {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
}

.website-item .options button {
    color: #0a0a0a !important;
}
body {
    letter-spacing: .01rem;
}

.primary-color {
    color: #6B0772
}

.shadow-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(34,41,47,.125);
    border-radius: .428rem;
    padding: 16px;
}

.app-btn-small {
    font-size: 12px;
}

.app-btn-outline-primary {
    border: 1px solid #6B0772;
    color: #6B0772;
    box-sizing: border-box;
    border-radius: 24px;
}

.app-btn-outline-primary:hover {
    border: 1px solid #6B0772;
    background-color: #f1e8f1;
    color: #6B0772;
}

.section-title {
    font-size: 0.8rem;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.5);
    word-wrap: break-word;
}

.section-counter {
    color: rgba(0,0,0,0.87);
    font-size: 36px;
}
.btn-label, .btn-label > button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border: none;
}
.btn-label:hover, .btn-label > button:hover {
    background-color: rgba(202, 201, 201, 0.1);
    color: #57035d
}

.avatar {
    background-color: #f5f5f5;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-label {
    color: #6e6b7b!important;
    font-size: 0.8rem!important;
    font-weight: 400;
    font-family: Helvetica, Arial, sans-serif;
}

.tree-select .dropdown-trigger {
    border-radius: 0.25rem;
}

.tree-select .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
}

.tree-select .toggle {
    font: normal normal normal 12px/1 FontAwesome;
    color: #555;
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0px;
}

.tree-select .root {
    padding: 0px;
    margin: 0px;
}

thead tr th, tbody tr td {
    white-space: nowrap;
}

.dropdown button.filter {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    font-size: 1rem;
    border: none;
    color: #57035d !important;
    padding: .375rem .75rem .375rem 1px;
}
.dropdown button.filter:after {
    display: none !important;
}

.dropdown button.filter:focus {
    box-shadow: unset !important;
}

.dropdown .dropdown-menu {
    max-height: 280px; overflow-y: auto; background-attachment: local, local, scroll, scroll;
}

.page-item.active .page-link {
    z-index: unset !important;
}
.note-info {
    color: #353535;
    font-size: 12px;
}
.highlight-value {
    color: #57035d;
    font-weight: bolder !important;
}
.config-btn button {
    background-color: transparent !important;
}

.config-btn .dropdown-menu {
    position: fixed;
}

@media (max-width: 991.98px) {
    .mw-90 {
        max-width: 90%;
        margin: auto;
    }
}
.color-baemin {
    color: #3ac5c9;
}
.color-grab {
    color: #00b14f;
}
.color-shopee {
    color: #ee4d2d;
}
.color-befood {
    color: #ffcc02;
}
.btn-baemin {
    color: #fff;
    background-color: #3ac5c9;
    border-color: #3ac5c9;
}
.btn-grab {
    color: #fff;
    background-color: #00b14f;
    border-color: #00b14f;
}
.btn-shopee {
    color: #fff;
    background-color: #ee4d2d;
    border-color: #ee4d2d;
}
.btn-gofood {
    color: #fff;
    background-color: #ed2937;
    border-color: #ed2937;
}
.btn-befood {
    color: #fff;
    background-color: #ffcc02;
    border-color: #ffcc02;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout div.pro-inner-list-item ul {
    padding-top: 0 !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout div.pro-inner-list-item ul li {
    padding-left: 21px;
}

table td div.position-absolute.dropdown {
    margin-top: -8px;
}